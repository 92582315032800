import { PublicRecord } from 'src/app/module/new-order/interface/new-order.interface';
import { ShortTermRental } from 'src/app/module/short-term-rental/interface/short-term-rental.interface';
import { OrderType, ValuationOrderSubType } from 'src/app/shared/interface/report.interface';
import { ValueAdjustmentRowConfig } from '../../adjustment-grid/interface/adjustment.interface';
import { FileType } from '../../file-viewer/interface/file-viewer.interface';
import { MultiPolygon } from '../../google-map/interface/google-map.interface';
import {
  ExtendedPropertyDetail,
  MlsStatusType,
  PropertyAttribute,
  PropertyAttributeValue,
  PropertyDetail,
} from '../../property/interface/property.interface';
import { CostApproach, LongTermRental } from './cost-approach.interface';
import { Neighborhood } from './neighborhood.interface';
import { ProjectInformation } from './project-information.interface';
import { SimilarityScoreAttributes } from './similarity-score.interface';
import { OrderMultiDataSource, ValueReconciliation } from './value-reconciliation.interface';

export interface OrderDetailApiResponse {
  order: OrderDetail;
}

export interface OrderDetailApiRequest {
  id: string;
}
export interface OrderDetail {
  appraisalForm: string;
  asyncDataStatus?: AsyncDataStatus;
  city: string;
  clientInfo: ClientInfo;
  companyKey: string;
  createdBy: string;
  createdOn: string;
  id: string;
  inspectionInfo?: InspectionInfo;
  lastUpdatedAt: string;
  lastUpdatedBy: string;
  orderId: string;
  orderStatus: string;
  orderType: OrderType;
  property?: any;
  reportType: ValuationOrderSubType;
  salesComparison: SalesComparison;
  costApproach?: CostApproach | null;
  shortTermRental?: ShortTermRental | null;
  longTermRental?: LongTermRental | null;
  projectInformation?: ProjectInformation;
  state: string;
  streetAddress: string;
  userName: string;
  zip: string;
  priorSale?: {
    comment: string;
  };
  neighborhood?: Neighborhood | null;
  valueReconciliation?: ValueReconciliation;
  additionalFiles?: AdditionalDoc[];
  certification: Certification;
  comments: string;
  additionalComments?: Array<{ key: string; value: string }>;
  effectiveDate?: Date | null;
}

export interface OrderDetailWithDisplayValue extends OrderDetail {
  orderTypeDisplayValue?: string;
}
export interface AsyncDataStatus {
  listingHistory?: AsyncDataStatusType | null;
  deedHistory?: AsyncDataStatusType | null;
  imageWithLabels?: AsyncDataStatusType | null;
  neighborhood?: AsyncDataStatusType | null;
}

export type OrderDetailKey = keyof OrderDetail;

export type AsyncDataStatusKey = keyof AsyncDataStatus;

export enum AsyncDataStatusType {
  completed = 'COMPLETED',
  pending = 'PENDING',
  failed = 'FAILED',
}

interface SalesComparison {
  adjustment: UnitAdjustment;
  comments: string | null;
  comparableList: string[];
  similarityScoreWeights: SimilarityScoreAttributes | null;
  derivedPrice: number;
  derivedPricePerSqft: number;
  primarySearchFilter: PrimarySearchFilter;
  propmixAdjustmentSettings: PropmixAdjustmentSetting;
  regressionAdjustmentModel?: RegressionAdjustmentModel;
  secondarySearchFilter: SecondarySearchFilter;
  selectedComparableList: SelectedComparable[] | null;
  customValueAdjustmentLineItem: ValueAdjustmentRowConfig[];
}

export interface SelectedComparable {
  id: string;
  ListingId: string;
}

export interface PrimarySearchFilter {
  bathsRange?: ValueRangeWithTolerance<number | null>;
  bedsRange?: ValueRangeWithTolerance<number | null>;
  effectiveDate?: Date | null;
  includePublicRecordSales?: boolean;
  limitToSubjectZip?: boolean;
  listDateRange?: ValueRange<Date | null> | null;
  livingAreaRange?: ValueRangeWithTolerance<number | null>;
  lotSizeRange?: ValueRangeWithTolerance<number | null>;
  monthsBack?: number | null;
  offMarketStatusList?: OffMarkerType[] | null;
  polygonList?: MultiPolygon | null;
  priceRange?: ValueRange<number | null> | null;
  propertyTypeList?: PropertyType[] | null;
  radius?: number | null;
  saleType?: SaleType | null;
  schoolDistrict?: string | null;
  searchMode?: CompSearchType;
  soldDateRange?: ValueRange<Date | null> | null;
  statusList?: StatusType[] | null;
  storiesRange?: ValueRangeWithTolerance<number | null>;
  timeframeOption?: TimeFrameType;
  valuationType: ValuationType;
  yearBuiltRange?: ValueRangeWithTolerance<number | null>;
  filterSource?: ValuationType;
}

export interface PropmixAdjustmentSetting {
  livingAreaContributionPercentage: number;
  lotSizeContributionPercentage: number;
  bedroomContributionPercentage: number;
  bathroomContributionPercentage: number;
  garageParkingContributionPercentage: number;
  conditionStepContributionPercentage: number;
  qualityStepContributionPercentage: number;
  basementTotalAreaUnitAdjustmentPercentage: number;
  basementFinishedAreaUnitAdjustmentPercentage: number;
  numberOfStoriesContributionPercentage: number;
  ageContributionPercentage: number;
  totalRoomsContributionPercentage: number;
}

export interface RegressionAdjustmentModel {
  rSquare: number | null;
  adjustedRSquare: number | null;
  standardError: number | null;
  fStat: number | null;
  significanceF: number | null;
  sampleSize: number | null;
  variableCount: number | null;
  model: RegressionModel;
}

export type RegressionModel = {
  [k in PropertyAttribute | 'intercept']: {
    displayValue: string;
    coefficient: number | null;
    pValue: number | null;
    standardError: number | null;
    tStat: number | null;
    value: number | null;
  };
};

export type ValueAdjustmentDataSourceType = 'propmixAdjustment' | 'pairedSalesAdjustment' | 'regressionAdjustment';

export type PropertyDataSourceType =
  | 'listingRecord'
  | 'rentalRecord'
  | 'publicRecord'
  | 'inspectionRecord'
  | 'userRecord';

export type DataSourceType = PropertyDataSourceType | ValueAdjustmentDataSourceType | keyof OrderMultiDataSource;

export interface UnitAdjustment {
  unitAdjustment?: Partial<ValueAdjustment>;
}

export type ValueAdjustment = {
  [key in PropertyAttribute]: number;
};

// export type MultiPolygon = Coordinates[][];

// interface Coordinates {
//   latitude: number;
//   longitude: number;
// }

export enum CompSearchType {
  radius = 'RADIUS',
  polygon = 'POLYGON',
}

export enum TimeFrameType {
  effectiveDate = 'effectiveDate',
  listingDate = 'listingDate',
}

export enum PropertyType {
  singleFamily = 'Single Family Residence',
  condominium = 'Condominium',
  duplex = 'Duplex',
  manufactured = 'Manufactured Home',
  mobile = 'Mobile Home',
  multiFamily = 'Multi Family',
  quadruplex = 'Quadruplex',
  triplex = 'Triplex',
  townhouse = 'Townhouse',
}

export enum SaleType {
  auction = 'auction',
  bankruptcy = 'bankruptcy',
  hud = 'hud',
  realEstateOwned = 'realEstateOwned',
  shortSale = 'shortSale',
  standard = 'standard',
}

export enum OffMarkerType {
  cancelled = 'Cancelled',
  delisted = 'Delisted',
  expired = 'Expired',
}

export enum StatusType {
  active = 'Active',
  sold = 'Sold',
  offMarket = 'OffMarket',
  pending = 'Pending',
}

export enum ValuationType {
  cost = 'COST',
  sales = 'SALES',
  neighborhood = 'NEIGHBORHOOD',
  shortTermRental = 'SHORT_TERM_RENTAL',
  longTermRental = 'LONG_TERM_RENTAL',
}

export interface ValueRange<T> {
  from?: T;
  to?: T;
}

export interface ValueRangeWithTolerance<T> extends ValueRange<T> {
  tolerance?: number | null;
  source?: PropertyDataSourceType | null;
}

export interface SecondarySearchFilter extends CompSearchSubFilter {
  pageNumber?: number;
  pageLimit?: number;
  similarityScoreType?: 'DEFAULT' | 'CUSTOM';
  fetchSelectedComparables?: boolean;
  sortOrder?: SortComparable;
  valuationType?: ValuationType;
}

export interface CompSearchSubFilter {
  includeHOA?: boolean | null;
  includeSolarPower?: boolean | null;
  includeGuestHouse?: boolean | null;
  includeFireplace?: boolean | null;
  includeGarage?: boolean | null;
  subDivisionName?: string;
  viewFactor?: ViewType | null;
  garageType?: GarageType | null;
  poolType?: PoolType | null;
  basementType?: BasementType | null;
  basementFinishedAreaRange?: ValueRange<number>;
  basementFinishedTotalAreaRange?: ValueRange<number>;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  propertyType?: PropertyType;
  status?: StatusType;
  bedsRange?: ValueRange<number>;
  bathsRange?: ValueRange<number>;
  livingAreaRange?: ValueRange<number>;
  listPriceRange?: ValueRange<number>;
  soldPriceRange?: ValueRange<number>;
  storiesRange?: ValueRange<number>;
  similarityScoreRange?: ValueRange<number>;
}

export type SecondarySearchFilterKeys = keyof SecondarySearchFilter;

export interface SelectedCompFilter {
  valuationType: ValuationType;
}

export interface SortComparable {
  fieldName?: string;
  sortOrderType?: string;
}

export enum ViewType {
  waterFront = 'Water Front',
  woods = 'Woods',
  park = 'Park',
  pastoral = 'Pastoral',
  golfCourse = 'Gold Course',
  citySkyline = 'City Sky Line',
  mountain = 'Mountain',
  residential = 'Residential',
  cityStreet = 'City Street',
  industrial = 'Industrial',
  powerLines = 'Power Lines',
  limitedSight = 'Limited Sight',
}

export enum GarageType {
  attached = 'Attached',
  builtIn = 'Built-In',
  carPort = 'Car Port',
  detached = 'Detached',
  unknown = 'Unknown',
}

export enum PoolType {
  inGround = 'In Ground',
  aboveGround = 'Above Ground',
  unknown = 'Unknown',
}

export enum BasementType {
  crawlSpace = 'Crawl Space',
  fullBasement = 'Full Basement',
  partialBasement = 'Partial Basement',
  unknown = 'Unknown',
}

export type ComparableTableDataSource = PropertyDetail & {
  index: number;
  id: string;
  isSubject?: boolean;
  isSelectedComparable?: boolean;
  isSticky: boolean;
};

export interface ComparableSelectionEvent {
  id: string;
  isSelected: boolean;
}
export interface PropertyIdentifyEvent {
  comparableId: string;
}

export interface ComparableProperty {
  id: string;
  index?: number;
  BathroomsDecimal: PropertyAttributeValue<string>;
  BathroomsTotalInteger: PropertyAttributeValue<string>;
  bathroomsTotal: PropertyAttributeValue<string>;
  BedroomsTotal: PropertyAttributeValue<string>;
  CloseDate: PropertyAttributeValue<string>;
  ClosePrice: PropertyAttributeValue<string>;
  DaysOnMarket: PropertyAttributeValue<string>;
  Distance: PropertyAttributeValue<string>;
  DistressType: PropertyAttributeValue<string>;
  ImageURLs: PropertyAttributeValue<string>;
  isSelectedComparable: boolean;
  Latitude: PropertyAttributeValue<string>;
  ListingContractDate: PropertyAttributeValue<string>;
  ListPrice: PropertyAttributeValue<string>;
  LivingArea: PropertyAttributeValue<string>;
  Longitude: PropertyAttributeValue<string>;
  LotSizeSquareFeet: PropertyAttributeValue<string>;
  MlsStatus: PropertyAttributeValue<MlsStatusType>;
  ModificationTimestamp: PropertyAttributeValue<string>;
  propertyCity: PropertyAttributeValue<string>;
  propertyState: PropertyAttributeValue<string>;
  propertyStreetAddress: PropertyAttributeValue<string>;
  PropertySubType: PropertyAttributeValue<string>;
  propertyZip: PropertyAttributeValue<string>;
  SimilarityScore: PropertyAttributeValue<string>;
  StandardStatus: PropertyAttributeValue<string>;
  Stories: PropertyAttributeValue<string>;
  SubdivisionName: PropertyAttributeValue<string>;
  pricePerSquareFeet?: PropertyAttributeValue<string>;
  BathroomsFullCalculated?: PropertyAttributeValue<string>;
  BathroomsHalfCalculated?: PropertyAttributeValue<string>;
}

export interface GetComparablePropertyApiResponse {
  comparableList: ComparableProperty[];
  pageLimit: number;
  pageNumber: number;
  totalCount: number;
}

export interface GeocodeSearchParams {
  Street: string;
  City: string;
  State: string;
  Zip: string;
}

interface Geocode {
  Latitude?: string;
  Longitude?: string;
}

interface StandardizedAddressComponents {
  City: string;
  Country: string;
  State: string;
  StreetName: string;
  StreetPrefix?: string;
  StreetSuffix?: string;
  StreetNumber: string;
  StreetNumberPrefix?: string;
  UnitNumber?: string;
  Zip: string;
}

export interface GeocodeApiResponse {
  responseData: {
    Status: any;
    Data: {
      Geocode: Geocode;
      StandardizedAddress: string;
      StandardizedAddressComponents: StandardizedAddressComponents;
    };
  };
}

export interface AddComparableParams {
  id: string;
  listingId?: string;
  userRecord: Partial<Record<PropertyAttribute, any>>;
  valuationType: ValuationType;
  pmxPropertyId?: string;
}

export interface RemoveComparableParams {
  id: string;
  propertyId: string;
}

export interface CustomCompAddress {
  streetAddress: string;
  streetNumber: string;
  streetName: string;
  unitNumber: string;
  city: string;
  state: string;
  zip: string;
  geoLocation: { lat: string; lng: string };
}

export interface CustomCompAttributes {
  status: string;
  listedFor: string;
  soldFor: string;
  propertyType: string;
  lotSizeSqFt: string;
  architecturalStyle: string;
  livingArea: string;
  yearBuilt: string;
  roomsTotal: string;
  bedrooms: string;
  bathroomsFull: string;
  bathroomsHalf: string;
  belowGradeTotalArea: string;
  belowGradeFinishedArea: string;
  parcelNumber: string;
  legalDescription: string;
  zoningDescription: string;
  zoningCompliance: string;
  zoning: string;
  owner1FullName: string;
  owner2FullName: string;
  associationYN: string;
  associationName: string;
  associationFee: string;
  associationFeeFrequency: string;
}

export interface AddNewCustomComparableParams {
  id: string;
  userRecord: Partial<Record<PropertyAttribute, any>>;
  valuationType: ValuationType;
}

export interface GetSelectedComparablePropertyApiResponse {
  totalCount: number;
  selectedComparables: ExtendedPropertyDetail[];
}

export interface ClientInfo {
  clientName?: number | null;
  clientFileId?: number | null;
  intendedUseOfValuation?: number | null;
  ownerName?: string | null;
  borrowerName?: string | null;
  assignmentObjective?: string | null;
}

export interface InspectionInfo {
  inspectionProvider?: string | null;
  inspectionOrderId?: string | null;
  inspectionType?: string | null;
  inspectionDate?: string | null;
  inspectorName?: string | null;
  inspectorPhone?: string | null;
  inspectorEmail?: string | null;
  inspectionRecordInfo?: Omit<InspectionInfo, 'inspectionRecordInfo'>;
}

export interface AdditionalDoc {
  url: string;
  fileReferenceId?: string;
  label: string; // File name by default
  fileType: FileType;
  isSelected: boolean;
  createdDate: Date;
}

export interface Certification {
  certificationList: string[];
  customCertificationList?: string[] | null;
  disclosure?: string;
  userName?: string;
  userPhoneNumber?: string;
  licenseNumber?: string;
  companyName?: string;
  companyAddress?: string;
  signDate?: Date | null;
  signImage?: string | null;
}

export interface OrderNavigation {
  section: string;
  subSection: string[];
}

export interface OrderRoutePathConfig {
  route: string;
  displayValue: string;
  jsonPath: string;
  children: Array<OrderRoutePathConfig>;
  companySettingJsonPath?: string | null;
  validationGroup: string[];
  validationLabel: string;
  validationRoute: string;
  orderType: Array<string>;
}

export interface AllStateCountyFips {
  counties: CountyFips;
}

export interface CountyFips {
  [key: string]: string;
}

export interface MLSBoardAPIResponse {
  responseData: MLSBoard[];
}

export interface MLSBoard {
  FeedId: number;
  MLSAbbreviation: string;
  MLSBoard: string;
  MLSDisplayAbbreviation: string;
  MLSName: string;
  ParentMLSAbbreviation: string;
  ParentMLSName: string;
}

export interface ValidateComparableExistsAPIRes {
  listingId: string;
  propertyId: string;
  propertyRole: 'SPECIFIC_COMPARABLE' | 'CUSTOM_COMPARABLE' | 'SUBJECT' | 'COMPARABLE';
}

export interface ValidateComparableExistsRequest {
  id: string;
  listingId: string | null;
  addressInfo: {
    streetAddress: string | null;
    unitNumber: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
  };
}

export interface AddCompValidationStatus {
  isDuplicate: boolean | null;
  isSelected: boolean | null;
  isRemovableComparable: boolean | null;
  notification: string;
  propertyInfo: ValidateComparableExistsAPIRes | null;
}

export interface AddCustomCompRes {
  listingId: string;
  propertyId: string;
}

export interface RedirectUrlApiRequest {
  id?: string;
  action: string;
  orderType?: string;
}

export interface RedirectUrlApiResponse {
  redirectURL: string;
  isOrderExistInMCA: boolean;
}

export interface PropertyMap {
  [key: string]: ExtendedPropertyDetail;
}

export interface SpecificComparable {
  Address?: string;
  ArchitecturalStyle?: string;
  BathroomsDecimal?: string;
  BathroomsFullCalculated?: string;
  BathroomsHalfCalculated?: string;
  BathroomsTotalInteger?: string;
  BedroomsTotal?: string;
  City?: string;
  CloseDate?: string;
  ClosePrice?: string;
  ConstructionQuality?: string;
  Cooling?: string;
  DataSource?: string;
  Distance?: string;
  FIPS?: string;
  FireplaceYN?: string;
  Flooring?: string;
  Heating?: string;
  ImageURLs?: string;
  Latitude?: string;
  ListingId?: string;
  LivingArea?: string;
  Longitude?: string;
  LotSizeSquareFeet?: string;
  MlsBoard?: string;
  MlsListingNumber?: string;
  OwnerName?: string;
  ParcelNumber?: string;
  ParkingSpaces?: string;
  PMXPropertyId?: string;
  PostalCode?: string;
  PropertySubType?: string;
  PropertyType?: string;
  PublicRecord?: PublicRecord;
  RoofCoverType?: string;
  RoomsTotal?: string;
  Source?: string;
  StateOrProvince?: string;
  Stories?: string;
  StreetAddress?: string;
  UnitNumber?: string;
  YearBuilt?: string;
}

export type ApproachKeys = 'salesComparison' | 'costApproach' | 'shortTermRental' | 'longTermRental';
