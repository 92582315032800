import { GridConfig } from 'src/app/module/adjustment-grid/interface/adjustment.interface';

export class AdjustmentGridConfig {
  static readonly salesApproachGridConfig: GridConfig = {
    headerHeight: 255,
    layout: [
      {
        key: 'generalInfo',
        displayText: 'Transaction',
        children: [
          {
            key: 'StandardStatus',
            propertyFieldList: ['StandardStatus'],
            displayText: 'Status',
            dataType: 'string',
            acceptCustomValue: false,
            hasAdjustment: true,
          },
          // {
          //   key: 'SpecialListingConditions',
          //   propertyFieldList: ['SpecialListingConditions'],
          //   displayText: 'Sale Type',
          //   dataType: 'string',
          //   acceptCustomValue: false,
          //   hasAdjustment: true,
          // },
          {
            key: 'CloseDate',
            propertyFieldList: ['CloseDate'],
            displayText: 'Sale Date',
            dataType: 'date',
            acceptCustomValue: false,
            hasAdjustment: true,
          },
          {
            key: 'PendingTimestamp',
            propertyFieldList: ['PendingTimestamp'],
            displayText: 'Contract Date',
            dataType: 'date',
            acceptCustomValue: false,
            hasAdjustment: true,
          },
          // {
          //   key: 'BuyerFinancing',
          //   propertyFieldList: ['BuyerFinancing'],
          //   displayText: 'Financing',
          //   dataType: 'string',
          //   acceptCustomValue: true,
          //   hasAdjustment: true,
          // },
          // {
          //   key: 'ConcessionsAmount',
          //   propertyFieldList: ['ConcessionsAmount'],
          //   displayText: 'Concessions',
          //   dataType: 'number',
          //   acceptCustomValue: true,
          //   hasAdjustment: true,
          // },
        ],
      },
      {
        key: 'site',
        displayText: 'Site',
        children: [
          {
            key: 'propertyRights',
            propertyFieldList: ['propertyRights'],
            displayText: 'Property Rights',
            dataType: 'string',
            acceptCustomValue: true,
            hasAdjustment: true,
          },
          {
            key: 'LocationRating',
            propertyFieldList: ['LocationRating', 'derivedLotFeatures'],
            displayText: 'Location',
            editor: 'location',
            hasUnitAdjustment: false,
            hasAdjustment: true,
          },
          {
            key: 'View',
            propertyFieldList: ['ViewRating', 'derivedView'],
            displayText: 'View',
            editor: 'view',
            hasUnitAdjustment: false,
            hasAdjustment: true,
          },
          // {
          //   key: 'NeighborhoodLocation',
          //   propertyFieldList: ['NeighborhoodLocation'],
          //   displayText: 'Neighborhood Location',
          //   dataType: 'string',
          //   acceptCustomValue: false,
          //   hasAdjustment: true,
          // },
          {
            key: 'LotSizeSquareFeet',
            propertyFieldList: ['LotSizeSquareFeet'],
            displayText: 'Lot Size',
            dataType: 'number',
            acceptCustomValue: true,
            hasUnitAdjustment: true,
            hasAdjustment: true,
          },
        ],
      },
      {
        key: 'dwelling',
        displayText: 'Dwelling(s)',
        children: [
          // {
          //   key: 'PropertySubType',
          //   propertyFieldList: ['PropertySubType'],
          //   displayText: 'Attachment Type',
          //   dataType: 'string',
          //   acceptCustomValue: false,
          //   hasAdjustment: true,
          // },
          {
            key: 'ArchitecturalStyle',
            propertyFieldList: ['ArchitecturalStyle'],
            displayText: 'Architectural Style',
            dataType: 'string',
            acceptCustomValue: true,
            hasAdjustment: true,
          },
          {
            key: 'YearBuilt',
            propertyFieldList: ['YearBuilt'],
            displayText: 'Age',
            dataType: 'numberString',
            acceptCustomValue: true,
            hasUnitAdjustment: true,
            hasAdjustment: true,
          },
          // {
          //   key: 'Heating',
          //   propertyFieldList: ['Heating'],
          //   displayText: 'Heating',
          //   dataType: 'string',
          //   acceptCustomValue: true,
          //   hasAdjustment: true,
          //   isMultiSelect: true,
          // },
          // {
          //   key: 'Cooling',
          //   propertyFieldList: ['Cooling'],
          //   displayText: 'Cooling',
          //   dataType: 'string',
          //   acceptCustomValue: true,
          //   hasAdjustment: true,
          //   isMultiSelect: true,
          // },
          // {
          //   key: 'FunctionalUtility',
          //   propertyFieldList: ['FunctionalUtility'],
          //   displayText: 'Functional Utility',
          //   dataType: 'string',
          //   acceptCustomValue: true,
          //   hasAdjustment: true,
          // },
        ],
      },
      {
        key: 'units',
        displayText: 'Unit(s)',
        children: [
          // {
          //   key: 'Stories',
          //   propertyFieldList: ['Stories'],
          //   displayText: 'Stories',
          //   dataType: 'number',
          //   acceptCustomValue: true,
          //   hasUnitAdjustment: true,
          //   hasAdjustment: true,
          // },
          {
            key: 'RoomsTotal',
            propertyFieldList: ['RoomsTotal'],
            displayText: 'Total Rooms',
            dataType: 'number',
            acceptCustomValue: true,
            hasUnitAdjustment: true,
            hasAdjustment: true,
          },
          {
            key: 'BedroomsTotal',
            propertyFieldList: ['BedroomsTotal'],
            displayText: 'Bedrooms',
            dataType: 'number',
            acceptCustomValue: true,
            hasUnitAdjustment: true,
            hasAdjustment: true,
          },
          {
            key: 'BathroomsFullCalculated',
            propertyFieldList: ['BathroomsFullCalculated'],
            displayText: 'Bathrooms Full',
            dataType: 'number',
            acceptCustomValue: true,
            hasAdjustment: true,
            hasUnitAdjustment: true,
          },
          {
            key: 'BathroomsHalfCalculated',
            propertyFieldList: ['BathroomsHalfCalculated'],
            displayText: 'Bathrooms Half',
            dataType: 'number',
            acceptCustomValue: true,
            hasAdjustment: true,
            hasUnitAdjustment: true,
          },
          {
            key: 'LivingArea',
            propertyFieldList: ['LivingArea'],
            displayText: 'Living Area',
            dataType: 'number',
            acceptCustomValue: true,
            hasUnitAdjustment: true,
            hasAdjustment: true,
          },
          {
            key: 'BelowGradeTotalArea',
            propertyFieldList: ['BelowGradeTotalArea'],
            displayText: 'Basement Total Area',
            dataType: 'number',
            acceptCustomValue: true,
            hasUnitAdjustment: true,
            hasAdjustment: true,
          },
          {
            key: 'BelowGradeFinishedArea',
            propertyFieldList: ['BelowGradeFinishedArea'],
            displayText: 'Basement Finished Area',
            dataType: 'number',
            acceptCustomValue: true,
            hasUnitAdjustment: true,
            hasAdjustment: true,
          },
          // {
          //   key: 'BasementEntry',
          //   propertyFieldList: ['BasementEntry'],
          //   displayText: 'Basement Entry',
          //   dataType: 'string',
          //   acceptCustomValue: true,
          //   hasAdjustment: true,
          // },
          // {
          //   key: 'Basement',
          //   propertyFieldList: [
          //     'BasementBedrooms',
          //     'BasementBathroomsFull',
          //     'BasementBathroomsHalf',
          //     'BasementRecRooms',
          //     'BasementOther',
          //   ],
          //   displayText: 'Basement Units',
          //   editor: 'basement',
          //   hasUnitAdjustment: true,
          //   hasAdjustment: true,
          // },
        ],
      },
      // {
      //   key: 'energy',
      //   displayText: 'Energy Efficient & Green Features',
      //   children: [
      //     {
      //       key: 'GreenEnergyEfficient',
      //       propertyFieldList: ['GreenEnergyEfficient'],
      //       displayText: 'Efficiency Rating',
      //       dataType: 'string',
      //       acceptCustomValue: true,
      //       hasAdjustment: true,
      //     },
      //     {
      //       key: 'GreenEnergyGeneration',
      //       propertyFieldList: ['GreenEnergyGeneration'],
      //       displayText: 'Energy Generation',
      //       dataType: 'string',
      //       acceptCustomValue: true,
      //       hasAdjustment: true,
      //     },
      //   ],
      // },
      {
        key: 'quality',
        displayText: 'Quality and Condition',
        children: [
          {
            key: 'derivedConstQuality',
            propertyFieldList: ['derivedConstQuality'],
            displayText: 'Quality of Construction',
            dataType: 'string',
            acceptCustomValue: false,
            hasAdjustment: true,
          },
          {
            key: 'derivedBuildingCondition',
            propertyFieldList: ['derivedBuildingCondition'],
            displayText: 'Condition',
            dataType: 'string',
            acceptCustomValue: false,
            hasAdjustment: true,
          },
        ],
      },
      {
        key: 'parking',
        displayText: 'Vehicle Storage',
        children: [
          {
            key: 'ParkingTotal',
            propertyFieldList: ['AttachedGarage', 'DetachedGarage', 'BuiltInGarage', 'Carport', 'Driveway'],
            displayText: 'Parking/Garage',
            editor: 'parking',
            hasUnitAdjustment: true,
            hasAdjustment: true,
          },
        ],
      },
      // {
      //   key: 'other',
      //   displayText: 'Amenities',
      //   children: [
      //     {
      //       key: 'FireplacesTotal',
      //       propertyFieldList: ['FireplacesTotal'],
      //       displayText: 'FireplacesTotal',
      //       dataType: 'number',
      //       acceptCustomValue: true,
      //       hasAdjustment: true,
      //       hasUnitAdjustment: true,
      //     },
      //     {
      //       key: 'Appliances',
      //       propertyFieldList: ['Appliances'],
      //       displayText: 'Appliances',
      //       dataType: 'string',
      //       acceptCustomValue: true,
      //       hasAdjustment: true,
      //       hasUnitAdjustment: false,
      //     },
      //     {
      //       key: 'PoolType',
      //       propertyFieldList: ['PoolType'],
      //       displayText: 'PoolType',
      //       dataType: 'string',
      //       acceptCustomValue: true,
      //       hasAdjustment: true,
      //       hasUnitAdjustment: false,
      //     },
      //   ],
      // },
      {
        key: 'custom',
        displayText: 'Custom',
        children: [],
      },
    ],
  };

  static readonly costApproachGridConfig: GridConfig = {
    headerHeight: 200,
    layout: [],
  };

  static readonly strApproachGridConfig: GridConfig = {
    headerHeight: 211,
    layout: [
      {
        key: 'preAdjustments',
        displayText: 'Pre-Adjustments',
        children: [
          {
            key: 'ListPriceNightly',
            propertyFieldList: ['ListPriceNightly'],
            displayText: 'Average Daily Rent',
            dataType: 'number',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: false,
            acceptCustomValue: true,
            hasAdjustment: false,
            hasUnitAdjustment: false,
          },
          {
            key: 'OccupancyRate',
            propertyFieldList: ['OccupancyRate'],
            displayText: 'Occupancy Rate',
            dataType: 'number',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: true,
            hasAdjustment: false,
            hasUnitAdjustment: false,
          },
          {
            key: 'RentalSource',
            propertyFieldList: ['RentalSource'],
            displayText: 'Data Source',
            dataType: 'string',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: true,
            hasAdjustment: false,
            hasUnitAdjustment: false,
          },
        ],
      },
      {
        key: 'rentAdjustmentsTrial',
        displayText: 'Rent Adjustments',
        children: [
          {
            key: 'LocationRating',
            propertyFieldList: ['LocationRating', 'derivedLotFeatures'],
            displayText: 'Location',
            editor: 'location',
            hasUnitAdjustment: false,
            hasAdjustment: true,
          },
          {
            key: 'View',
            propertyFieldList: ['ViewRating', 'derivedView'],
            displayText: 'View',
            editor: 'view',
            hasUnitAdjustment: false,
            hasAdjustment: true,
          },
          {
            key: 'derivedAttachmentType',
            propertyFieldList: ['derivedAttachmentType'],
            displayText: 'Attachment Type',
            dataType: 'string',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: true,
            hasAdjustment: true,
            hasUnitAdjustment: false,
          },
          {
            key: 'Stories',
            propertyFieldList: ['Stories'],
            displayText: 'Stories',
            dataType: 'number',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: true,
            hasAdjustment: true,
            hasUnitAdjustment: true,
          },
          {
            key: 'ArchitecturalStyle',
            propertyFieldList: ['ArchitecturalStyle'],
            displayText: 'Architectural Style',
            dataType: 'string',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: true,
            hasAdjustment: true,
            hasUnitAdjustment: false,
          },
          {
            key: 'appeal',
            propertyFieldList: ['appeal'],
            displayText: 'Appeal',
            dataType: 'string',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: true,
            hasAdjustment: true,
            hasUnitAdjustment: false,
          },
          {
            key: 'derivedConstQuality',
            propertyFieldList: ['derivedConstQuality'],
            displayText: 'Quality',
            dataType: 'string',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: false,
            hasAdjustment: true,
            hasUnitAdjustment: false,
          },
          {
            key: 'YearBuilt',
            propertyFieldList: ['YearBuilt'],
            displayText: 'Age (Year Built)',
            dataType: 'numberString',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: true,
            hasAdjustment: true,
            hasUnitAdjustment: true,
          },
          {
            key: 'derivedBuildingCondition',
            propertyFieldList: ['derivedBuildingCondition'],
            displayText: 'Condition',
            dataType: 'string',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: true,
            hasAdjustment: true,
            hasUnitAdjustment: false,
          },
          {
            key: 'RoomsTotal',
            propertyFieldList: ['RoomsTotal'],
            displayText: 'Total Rooms',
            dataType: 'number',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: true,
            hasAdjustment: true,
            hasUnitAdjustment: true,
          },
          {
            key: 'BedroomsTotal',
            propertyFieldList: ['BedroomsTotal'],
            displayText: 'Bedrooms',
            dataType: 'number',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: true,
            hasAdjustment: true,
            hasUnitAdjustment: true,
          },
          {
            key: 'BathroomsFullCalculated',
            propertyFieldList: ['BathroomsFullCalculated'],
            displayText: 'Bathrooms Full',
            dataType: 'number',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: true,
            hasAdjustment: true,
            hasUnitAdjustment: true,
          },
          {
            key: 'BathroomsHalfCalculated',
            propertyFieldList: ['BathroomsHalfCalculated'],
            displayText: 'Bathrooms Half',
            dataType: 'number',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: true,
            hasAdjustment: true,
            hasUnitAdjustment: true,
          },
          {
            key: 'LivingArea',
            propertyFieldList: ['LivingArea'],
            displayText: 'Living Area',
            dataType: 'number',
            isEditable: true,
            isMultiSelect: false,
            isCustomField: false,
            isSubjectEditable: true,
            acceptCustomValue: true,
            hasAdjustment: true,
            hasUnitAdjustment: true,
          },
          {
            key: 'BelowGradeTotalArea',
            propertyFieldList: ['BelowGradeTotalArea'],
            displayText: 'Basement Total Area',
            dataType: 'number',
            acceptCustomValue: true,
            hasUnitAdjustment: true,
            hasAdjustment: true,
          },
          // {
          //   key: 'Basement',
          //   propertyFieldList: [
          //     'BasementBedrooms',
          //     'BasementBathroomsFull',
          //     'BasementBathroomsHalf',
          //     'BasementRecRooms',
          //     'BasementOther',
          //   ],
          //   displayText: 'Basement Units',
          //   editor: 'basement',
          //   hasUnitAdjustment: false,
          //   hasAdjustment: true,
          // },
        ],
      },
      {
        key: 'custom',
        displayText: 'Custom',
        children: [],
      },
    ],
  };

  static readonly ltrApproachGridConfig: GridConfig = {
    headerHeight: 200,
    layout: [],
  };
}
